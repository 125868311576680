.body-wrapper {
    padding: 0 12px 0 12px;
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 8px;
}

.body-wrapper-nopad {
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 8px;
}

.body-wrapper::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
}

.body-wrapper:focus {
    /* Evita el foco cuando se presiona ESC */
    outline: none;
}

.profile-info {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    /* Evita que el avatar y el nombre se encojan */
}

.header-wrapper {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    flex-wrap: wrap;
}

@media (max-width: 767px) {
    .header-wrapper {
        padding: 0 50px;
        /* Padding para resoluciones pequeñas y medianas */
    }
}

.sider-image-wrapper {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 80px;
    cursor: pointer;
}


.avatar-header {
    border: 1px solid transparent;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.avatar-header:hover {
    border-color: #d9d9d9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.typo-header {
    font-weight: 500;
    color: #333;
    transition: transform 0.2s ease;
}

.typo-header:hover {
    transform: scale(1.005);
}


/* LOGO OnePOS */

.sider-image-wrapper img {
    transition: width 1s ease, opacity 1s ease;
    opacity: 1;
}