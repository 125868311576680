.return-button-404 {
    width: 200px;
}

.main-text {
    font-size: 100px;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main-text {
    font-size: 100px;
}

.bottom-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    width: 250px;
}