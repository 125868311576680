.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f7f7f7;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
    max-width: 100px;
    height: auto;
}

.message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    /* Para que ocupe todo el espacio disponible */
    text-align: center;
}

.message-card {
    width: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer {
    text-align: center;
    padding: 10px 0;
    background-color: #f0f0f0;
}