.filter-wrapper {
    display: flex;
    flex-direction: row;
}

.custom-tag {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #f0f0f0;
    transition: background-color 0.3s;
}

.custom-tag:hover {
    background-color: #e0e0e0;
}


.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.filter-right-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px
}