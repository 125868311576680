.cards-integration {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin: 12px; */
    padding: 12px;
    align-items: center;
    height: 100%;
    background-color: white;
}

.integration-wrapper {
    height: 70dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-image {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
}

.card-image .ant-card-body {
    padding: 8px !important;
}

.card-image:hover {
    transform: scale(1.003);
    cursor: pointer;
}

.disabled-card {
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    pointer-events: none;
    filter: grayscale(100%);
    margin: 10px;
}

.integration-name:hover {
    text-decoration: underline;
}

.datesync-input {
    padding-left: 0 !important;
}

.sync-wrapper {
    display: flex;
    justify-content: space-between;
}


.ant-tabs-nav {
    position: static !important;
}


.ant-tabs-nav {
    margin-bottom: 10px !important
}

.sync-disp-wrapper {
    padding: 10px;
    bottom: 0;
    width: 90%;
}