.filter-wrapper {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
    padding: 8px 0 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
}

.table-wrapper {
    position: relative;
    z-index: 900;
}