.integrations-col-wrapper {
    display: flex;
    gap: 8px;
    padding: 6px;
}

.edit-icon {
    color: black;
    transition: transform 0.3s;
    /* Transición suave para el escalado */
}

.edit-icon:hover {
    transform: scale(1.1);
    /* Escala ligeramente el ícono al pasar el mouse */
}

.ant-collapse-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ant-collapse-content-box {
    padding: 0 !important;
}


.card-integration-container {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    padding: 6px;
    scrollbar-width: none;
}

.disabled-card-integration {
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    filter: grayscale(100%);
    margin: 10px;
}