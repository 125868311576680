.health-app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.health-main-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.health-api-check-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.health-main-text {
    font-size: 80px;
}

.health-bottom-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    width: 200px;
}

.health-image-wrapper {
    top: 0;
    position: absolute;
    margin-top: 40px;
}